import { Maybe } from "./Types";

export enum DefaultUserRole {
  ADMIN = "admin",
  CONTRACT_REVIEWER = "contract_reviewer",
  NONE = "none",
}

export type TOrganization = {
  id: string;
  name: string;
  isPersonal: boolean;
  defaultRoleForNewUsers: DefaultUserRole;
  createdAt: string;
  updatedAt: string;
  trialEndDate: Maybe<string>;
  disabledAt: Maybe<string>;
  isPincites: Maybe<boolean>;
};

export function isOrganization(arg: unknown): arg is TOrganization {
  return (
    !!arg &&
    typeof arg === "object" &&
    "id" in arg &&
    typeof arg.id === "string" &&
    "name" in arg &&
    typeof arg.name === "string" &&
    "isPersonal" in arg &&
    typeof arg.isPersonal === "boolean" &&
    "defaultRoleForNewUsers" in arg &&
    typeof arg.defaultRoleForNewUsers === "string" &&
    Object.values(DefaultUserRole).includes(arg.defaultRoleForNewUsers as DefaultUserRole) &&
    "createdAt" in arg &&
    typeof arg.createdAt === "string" &&
    "updatedAt" in arg &&
    typeof arg.updatedAt === "string" &&
    "trialEndDate" in arg &&
    (arg.trialEndDate === null || typeof arg.trialEndDate === "string") &&
    "disabledAt" in arg &&
    (arg.disabledAt === null || typeof arg.disabledAt === "string") &&
    (!("isPincites" in arg) || ["boolean", "undefined"].includes(typeof arg.isPincites))
  );
}

export type TMicrosoftOrganization = {
  id: string;
  displayName: string;
  verifiedDomains: string[];
};

export function getMicrosoftOrganizationFromAPIResponse(response: any): TMicrosoftOrganization {
  return {
    id: response.id,
    displayName: response.displayName,
    verifiedDomains: response.verifiedDomains.map((domain: any) => domain.name),
  };
}
