import React, { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import {
  PincitesFlexbox,
  PincitesFlexboxDirection,
  PincitesFlexboxAlignItems,
  PincitesFlexboxGap,
} from "./PincitesFlexbox";
import { PincitesButtonType } from "../../utils/buttonUtils";
import { PincitesButton } from "./PincitesButton";
import { PincitesField, PincitesFieldValidationState } from "./PincitesField";

const useStyles = makeStyles({
  dialogSurfaceBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
});

type PincitesDialogActionButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  buttonType?: PincitesButtonType;
  showSpinner?: boolean;
  icon?: React.JSX.Element;
};

type DialogHeaderProps = {
  headingText: string | ReactNode;
  icon?: React.JSX.Element;
  customStyle?: string;
  isErrorDialog?: boolean;
};

export enum PincitesModalType {
  ALERT = "alert", // Can't close by clicking outside the dialog
  MODAL = "modal", // Can close by clicking outside the dialog
  NON_MODAL = "non-modal", // User can interact with rest of the page
}

export function PincitesDialog({
  isOpen,
  onDismiss,
  modalType = PincitesModalType.MODAL,
  primaryActionButtonProps,
  dialogHeaderProps,
  validationConfig,
  children,
}: {
  isOpen: boolean;
  onDismiss?: () => void;
  modalType?: PincitesModalType;
  dialogHeaderProps: DialogHeaderProps;
  primaryActionButtonProps: PincitesDialogActionButtonProps;
  validationConfig?: {
    validationMessage: string;
    validationState: PincitesFieldValidationState;
  };
  children: ReactNode;
}) {
  const styles = useStyles();

  const headerComponent = (
    <PincitesDialogHeader
      headingText={dialogHeaderProps.headingText}
      icon={dialogHeaderProps.icon}
      customStyle={dialogHeaderProps.customStyle}
    />
  );

  return (
    <Dialog open={isOpen} onOpenChange={onDismiss} modalType={modalType}>
      <DialogSurface
        aria-label="open-dialog"
        id="dialog-surface"
        backdrop={{ className: styles.dialogSurfaceBackdrop }}
      >
        <DialogBody id="dialog-body">
          <DialogTitle id="dialog-title">
            {validationConfig ? (
              <PincitesField
                validationState={validationConfig.validationState}
                validationMessage={validationConfig.validationMessage}
              >
                {headerComponent}
              </PincitesField>
            ) : (
              headerComponent
            )}
          </DialogTitle>
          <DialogContent id="dialog-content">{children}</DialogContent>
          <DialogActions aria-label="dialog-actions" fluid>
            {onDismiss && (
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" aria-label="Cancel">
                  Cancel
                </Button>
              </DialogTrigger>
            )}
            <PincitesButton
              label={primaryActionButtonProps.title}
              onClick={primaryActionButtonProps.onClick}
              isDisabled={primaryActionButtonProps?.disabled}
              showSpinner={primaryActionButtonProps?.showSpinner}
              buttonType={primaryActionButtonProps?.buttonType || PincitesButtonType.PRIMARY}
              icon={primaryActionButtonProps?.icon || undefined}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

function PincitesDialogHeader({ headingText, icon, customStyle }: DialogHeaderProps) {
  return icon ? (
    <PincitesFlexbox
      direction={PincitesFlexboxDirection.HORIZONTAL}
      alignItems={PincitesFlexboxAlignItems.CENTER}
      gap={PincitesFlexboxGap.GAP_4}
      customClassName={customStyle}
      id="dialog-title-flexbox"
    >
      {icon}
      {headingText}
    </PincitesFlexbox>
  ) : (
    headingText
  );
}
